import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import classes from "./Carousel.module.css";

const caraousel = () => {
	return (
		<div style={{ marginTop: "10px" }}>
			<>
				<Row>
					<Col md={0} lg={2}></Col>
					<Col sm={12} md={12} lg={8}>
						<Carousel className={classes.Carousel}>
							<Carousel.Item interval={3000}>
								<img
									className="d-block w-100"
									src="First.jpg"
									alt="First slide"
								/>
								<Carousel.Caption>
									Sandeep Kumar
								</Carousel.Caption>
							</Carousel.Item>
							<Carousel.Item interval={3000}>
								<img
									className="d-block w-100"
									src="Second.jpg"
									alt="Second slide"
								/>

								<Carousel.Caption>
									Sandeep Kumar
								</Carousel.Caption>
							</Carousel.Item>
							<Carousel.Item interval={3000}>
								<img
									className="d-block w-100"
									src="Third.jpg"
									alt="Third slide"
								/>

								<Carousel.Caption>
									Sandeep Kumar
								</Carousel.Caption>
							</Carousel.Item>
							<Carousel.Item interval={3000}>
								<img
									className="d-block w-100"
									src="Fourth.jpg"
									alt="Fourth slide"
								/>

								<Carousel.Caption>
									Sandeep Kumar
								</Carousel.Caption>
							</Carousel.Item>
							<Carousel.Item interval={3000}>
								<img
									className="d-block w-100"
									src="Fifth.jpg"
									alt="Fifth slide"
								/>

								<Carousel.Caption>
									Sandeep Kumar
								</Carousel.Caption>
							</Carousel.Item>
							<Carousel.Item interval={3000}>
								<img
									className="d-block w-100"
									src="Sixth.jpg"
									alt="Sixth slide"
								/>

								<Carousel.Caption>
									Sandeep Kumar
								</Carousel.Caption>
							</Carousel.Item>
						</Carousel>
					</Col>
					<Col md={0} lg={2}></Col>
				</Row>
			</>
		</div>
	);
};

export default caraousel;
