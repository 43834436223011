import './App.css';
import React,{useEffect} from 'react';
import Navbar from './UI/Navbar/Navbar';
import Carousel from './UI/Carousel/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Details from './UI/Details/Details';
import ReactGa from 'react-ga';
const App=()=> {
  useEffect(()=>{
    ReactGa.initialize('UA-182704065-1');
    ReactGa.pageview('/');
  },[])
  return (
    <div className="App">
      <Navbar />
      <Row style={{ marginTop: "50px" }}>
        <Col sm={6}>
          <Carousel />
        </Col>
        <Col sm={6}>
          <Details />
        </Col>
      </Row>
      <Row>
     
      </Row>
     
    </div>
  );
}

export default App;
