import React,{useEffect} from 'react';
import Navbar from 'react-bootstrap/Navbar';
import ReactGA from 'react-ga';
import AppConfig from '../../App.config';
import {withRouter} from 'react-router-dom';
ReactGA.initialize(AppConfig.GOOGLE.GA_TRACKING_CODE);
const NAvbar = () => {
    useEffect(()=>{
        ReactGA.pageview(window.location.pathname+window.location.search);
      });
    return (
        <Navbar fixed="top" bg="dark" variant="dark" >
            <Navbar.Brand href="#home">
                {/* <img
                    alt=""
                    src="/logo.svg"
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                />{' '} */}
                    Sandeep Kumar
                    </Navbar.Brand>
        </Navbar>
    )
}
export default withRouter(NAvbar);