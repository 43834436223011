import React from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
const details = () => {
    return (
        <Card style={{ width: '80%', margin:"10px auto" }}>
             <Card.Header style={{textAlign:"left",fontSize:"1.2rem",fontFamily:"Lemon"}}><p><strong>Er. Sandeep Kumar</strong><br/><span style={{fontSize:"0.95rem"}}>(Business Developer)</span></p></Card.Header>
            <ListGroup variant="flush">
                <ListGroup.Item style={{textAlign:"left",fontSize:"1.2rem"}}><strong>CEO: </strong><br/><strong><a href="http://sultandecors.com/">Sultan Decors</a></strong></ListGroup.Item>
                <ListGroup.Item style={{textAlign:"left",fontSize:"1.2rem"}}><strong>Prop : </strong><br/>
                    <strong style={{fontSize:"1rem"}}>1) <a href="http://chandigarhmedicaltourism.com/">Chandigarh Medical Tourism</a></strong><br/>
                    <strong style={{fontSize:"1rem"}}>2) <a href="http://panjabforbes.in/">Panjab Forbes</a></strong>
                </ListGroup.Item>
                <ListGroup.Item style={{textAlign:"left",fontSize:"1.2rem"}}><strong>Mobile No: </strong><img src="https://img.icons8.com/color/48/000000/whatsapp--v1.png"/><br/><span style={{fontSize:"1.0rem"}}>+91-9988900406<br/> +91-9417228733</span></ListGroup.Item>
            </ListGroup>
        </Card>
    )
}
export default details;